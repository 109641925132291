import styled from 'styled-components';
import { space, color, border } from 'styled-system';
import {
  IconButton,
  Row,
  Col,
  Label,
  Input,
  Button,
  TextArea,
  Modal,
} from '@d-lighted/design-system';
import Text from 'components/Texts/Text13';

import * as colors from 'constants/colors';
import * as screenSize from 'constants/screenSize';
import BaseButton from 'components/Buttons/BaseButton';
import Text18 from 'components/Texts/Text18';
import Text14 from 'components/Texts/Text14';

export const MainRow = styled(Row)`
  width: 100%;
  min-width: 1103px; // Minimum width including overflow
  flex-wrap: nowrap !important;
`;

export const ButtonCol = styled(Col)`
  button {
    word-break: keep-all;
    min-width: 120px;
    transition: font-size 0.3s linear;
  }

  @media (max-width: ${screenSize.MScreen}px) {
    * {
      font-size: 11px !important;
    }
  }
`;

export const TransparentIconButton = styled(IconButton)`
  background-color: transparent;
  width: auto;
  padding-right: 0;

  &:hover {
    background-color: transparent;
  }
`;

export const SlotSearchButton = styled(BaseButton)`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5.5px 16px 5px;
  border-radius: 3px;
  background-color: ${colors.white};
  border: 1px solid ${colors.primaryLightGreen};
  color: ${colors.primaryLightGreen};
  font-size: 16px;
`;

export const IconFrame = styled.img`
  margin-left: 10px;
`;

export const CustomModal = styled(Modal)`
  border-radius: 6px;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
`;

export const DialogLabel = styled(Label)`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.darkBlue};
`;

export const SeparatorIcon = styled.div`
  color: #68878d;
  margin-top: -3px;
  margin-left: 13px;
  margin-right: 13px;
  width: 13px;
`;

export const TimeSelector = styled.div`
  height: 36px;
  background: ${props => (props.isSelected ? colors.brandBlue : colors.white)};
  border-radius: 4px;
  border: 1px solid
    ${props =>
      props.isSelected ? colors.todayIndicator : colors.tagInactiveBorder};
  color: ${props => (props.isSelected ? colors.white : colors.doneEventGray)};
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  display: flex;
  cursor: pointer;
`;

export const NotCreatableMessage = styled.div`
  color: ${colors.grayThin3};
  font-size: 14px;
  min-height: 24px;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmationButton = styled(Button)`
  width: auto !important;
  min-width: 60px;
  padding: 0 15px;

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled(Button)`
  color: ${colors.darkBlue} !important;
  background-color: transparent !important;
  width: auto !important;
  min-width: 60px;
  padding: 0 15px;

  &:hover {
    background-color: ${colors.lightGray} !important;
  }
`;

export const CalendarWrapper = styled.div`
  .dropdown__content {
    z-index: 4;
  }
`;

export const TagWrapper = styled.div`
  border-radius: 50px;
  display: flex;
  align-items: center;
  ${space}
  ${color}
  ${border}
`;

export const TagText = styled.p`
  font-family: Noto Sans JP;
  font-size: ${props => (props.fullscreenState ? '20px' : '13px')};
  color: ${colors.grayThin3};
`;

export const TagIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 1.5px;
  margin-right: 7px;
  ${color}
  ${border}
`;

export const ResourceHeaderText = styled(Text)`
  line-height: 1.5;
  width: ${({ editMode, additionalInformation }) => {
    if (editMode) return '100px';
    if (additionalInformation) return '100%';

    return '130px';
  }};
  ${({ additionalInformation }) => {
    if (additionalInformation)
      return `
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        `;

    return 'white-space: normal;';
  }}
  margin-bottom: 6px;
  @media (min-width: ${screenSize.LScreen}px) {
    font-size: ${props => (props.fullScreenState ? '20px' : '16px')};
  }
  @media (min-width: ${screenSize.XLScreen}px) {
    font-size: ${props => (props.fullScreenState ? '20px' : '16px')};
  }
`;

/* eslint-disable prettier/prettier */
// Calc has a very strict syntax following prettier breaks the styles
export const ResourceCapacityText = styled(Text)`
  width: calc(100% - ${({ editMode, additionalInformation }) => {
    if (editMode) return '20px';
    if (additionalInformation) return '100%';

    return '30px';
  }});
  font-size: ${props => (props.fullScreenState ? '16px' : '11px')};
  font-weight: normal;
`;
/* eslint-enable prettier/prettier */

export const CustomResourceHeader = styled.div`
  background-color: ${props => {
    if (props.busyResource && !props.editMode) {
      return colors.busyBGGray;
    }
    if (!props.visible) {
      return colors.lightGray;
    }
    return colors.white;
  }};
  display: flex;
  width: 100%;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => (props.editMode ? 'row' : 'column')};
  border-radius: 10px 10px 0 0;
  cursor: ${props => (props.editMode ? 'pointer' : 'default')};
`;

export const CustomForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 16px;
  .confirm_button {
    background-color: ${colors.brandBlue};
  }
  .cancel_button {
    color: ${colors.brandBlue} !important;
    border: 2px solid ${colors.brandBlue};
  }
  .space_top {
    padding: 20px 0;
  }
`;

export const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.darkBlue};
  padding-left: 8px;
  word-break: break-all;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 24px;
  cursor: pointer;
`;

export const TitleLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${colors.darkBlue};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-align: left;
  cursor: default;
  letter-spacing: 0.1px;
  margin-top: 4px;
  white-space: pre;
  text-wrap: wrap;
`;

export const DescriptionText = styled.div`
  font-size: 12px;
  font-weight: 200;
`;

export const InputField = styled(Input)`
  height: 48px;
  background: ${colors.lightGray};
  border-radius: 11px;
  border-color: ${colors.lightGray};

  & + div {
    font-size: 14px;
    position: relative !important;
  }
`;

export const TextAreaField = styled(TextArea)`
  & + div {
    font-size: 14px;
    position: relative !important;
  }
`;

export const ConfirmationDialogButton = styled(Button)`
  width: 28px;
  height: 47px;
  border-radius: 4px;
  font-weight: 600;
  margin-right: 8px;
`;

export const EventDetailsContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorIndicator = styled.div`
  border-radius: 4px;
  height: 20px;
  width: 20px;
  background-color: ${props => props.color};
  border: ${props => props.border || null};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 24px;
`;

export const MoreLoaderContainer = styled.div`
  z-index: 4;
  padding: 2px;
  position: absolute;
  top: 50%;
  right: 20px;
`;

export const EyeIconWrapper = styled.div`
  padding: 6px;
  border: ${props =>
    props.visible ? `1px solid ${colors.incomingEvent}` : '1px solid #BCE7F0'};
  color: ${colors.incomingEvent};
  border-radius: 6px;

  &:hover {
    box-shadow: ${props => (props.edit ? '0 0 1px 3px #B2E7F0' : 0)};
  }
`;

export const FullscreenHeaderWrapper = styled.header`
  height: 42.5px;
  width: 100%;
  padding: 24px 0px;
  margin-bottom: 20px;
  background-color: ${color.hoverGray};
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ExitFullScreenButton = styled(Button)`
  width: 170px;
  padding: 0px 4px;
  margin-right: 20px;
  background: ${colors.white};
  color: ${colors.grayThin3};

  &:hover {
    background: ${colors.white};
    color: ${colors.grayThin3};
  }

  &:focus {
    background: ${colors.white};
    color: ${colors.grayThin3};
  }
`;

export const ExitFullScreenButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const MainWrapper = styled.div`
  .rbc-day-slot {
    cursor: ${({ edit }) => (edit ? 'pointer' : 'default')};
  }
`;

export const DateHeaderBox = styled.div`
  height: ${p =>
    p.fullscreenState ? 'calc(100vh - 30px)' : 'calc(100vh - 70px)'};
  width: 100%;
  .rbc-time-header .rbc-time-header-content {
    border-bottom: ${p => (p.today ? 'none' : 'solid 1px #ddd')};
  }
`;

export const SignageModeLogo = styled.img`
  height: 50px;
  width: 200px;
`;

export const CountdownWrapper = styled.div`
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-top: 0px;
`;

export const NotesCol = styled(Col)`
  word-break: break-all;
  line-height: 16px;
  margin-top: 4px;
`;

export const ResourceWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 4px;
  cursor: pointer;
`;

export const ResourceThumbnail = styled.img`
  width; 152px;
  height: 108px;
  border-radius: 4px 4px 0 0;
  margin-bottom: 4px;
  object-fit: cover;
`;

export const ResourcesInfo = styled.div`
  padding: 4px;
`;

export const ResourcesSubInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DetailsContainer = styled.div`
  position: absolute;
  top: ${props => (props.top ? props.top : 0)};
  left: ${props => (props.left ? props.left : '100%')};
  width: 400px;
  border-radius: 4px;
  border: 1px solid #f6f8fa;
  box-shadow: 0 12px 40px 0 rgba(156, 168, 184, 0.72);
  background: #fff;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  padding: 24px;
  margin-left: 24px;
  flex-direction: column;
  z-index: 10;
`;

export const RoomDetailsTitle = styled(Text18)`
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

export const InformationRow = styled(Row)`
  font-weight: 400;
  color: #264153;
  line-height: 22.4px;
  text-align: left;
  font-size: 12px;
  padding: 3px 12px;
`;

export const InformationLabel = styled(Col).attrs(() => {
  return {
    xs: 5,
  };
})`
  padding: 0;
  color: ${color.grayThin0};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ValueLabel = styled(Col).attrs(() => {
  return {
    xs: 7,
  };
})`
  padding: 0;
  line-height: initial;
  white-space: pre-line;
`;

export const RoomDetailsInfo = styled(Text14)`
  font-weight: 400;
  color: #264153;
  line-height: 22.4px;
  text-align: left;
  margin-top: ${props => (props.mt ? props.mt : 0)};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const ScrollWrapper = styled.div`
  max-height: 250px;
  overflow-y: scroll;
  margin-top: 10px;
`;

export const InfoIcon = styled.img``;

export const CloseText = styled(Text14)`
  font-weight: 700;
  line-height: 16.8px;
  text-align: right;
  color: #264153;
  margin: 16px;
  cursor: pointer;
`;

export const RoomDetailsImage = styled.img`
  width: 352px;
  height: 264px;
  border-radius: 4px;
  margin-top: 12px;
`;
