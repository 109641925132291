import React from 'react';
import { NavAccount, ProductDropDown } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { PropTypes } from 'prop-types';

import { pushEvent, events } from 'utils/gtmUtils';
import { isMobileDevice } from 'utils/mobileUtils';
import { LOCALES, useLocale } from 'hooks/useLocale';
import {
  getCurrentUser,
  isItokiCompanyUser,
  isNissanEmployee,
} from 'utils/userUtils';
import AccountIcon from 'images/account.svg';
import IntegrationIcon from 'images/integration.svg';
import LogoutIcon from 'images/logout.svg';
import RoomsLogoIcon from 'images/img_logo_rooms.svg';
import { useOneTimeToken } from 'hooks/useOneTimeToken';
import * as webViewUtils from 'utils/webViewUtils';
import messages from './messages';
import {
  HeaderWrapper,
  HeaderRight,
  HeaderLeft,
  Icon,
  Anchor,
  LangSelector,
  LangText,
} from './components/styled';
import { MobileHeader } from './components/Mobile_Header';

function Header({ setCurrentUser, hideRightComponent, styleProps, ...props }) {
  const [locale, setLocale] = useLocale();
  const {
    openWeb2RouteWithToken,
    openSchedulingRouteWithToken,
  } = useOneTimeToken();

  const currentUser = getCurrentUser();
  const isKhiCompanyUser = currentUser?.company?.id === 12036;
  const history = useHistory();
  const { pb } = styleProps;
  // Removed until the product info page is finished
  const otherProducts = [
    // {
    //   name: <FormattedMessage {...messages.otherName} />,
    //   description: <FormattedMessage {...messages.otherProducts} />,
    //   product_type: 'other',
    //   isActive: true,
    //   link: '/product',
    // },
  ];
  const ownedProducts = [];
  const productContent = {
    reception: {
      name: <FormattedMessage {...messages.receptionName} />,
      description: <FormattedMessage {...messages.receptionDescription} />,
      onClick: () => openWeb2RouteWithToken('/'),
      link: 'https://receptionist.jp',
    },
    scheduling: {
      name: <FormattedMessage {...messages.schedulingName} />,
      description: <FormattedMessage {...messages.schedulingDescription} />,
      onClick: () => openSchedulingRouteWithToken('/'),
      link: 'https://scheduling.receptionist.jp',
    },
    other: {
      name: <FormattedMessage {...messages.otherName} />,
      description: <FormattedMessage {...messages.otherDescription} />,
    },
  };

  const accountList = [
    {
      label: <FormattedMessage {...messages.accountInfo} />,
      icon: AccountIcon,
      onClick: () => {
        openWeb2RouteWithToken('info/account');
        pushEvent({ ...events.onClickLinkApp('/info/account') });
      },
    },
    {
      label: <FormattedMessage {...messages.integrations} />,
      icon: IntegrationIcon,
      onClick: () => {
        openWeb2RouteWithToken('info/integrations');
        pushEvent({ ...events.onClickLinkApp('/info/integrations') });
      },
    },
    {
      label: <FormattedMessage {...messages.logout} />,
      icon: LogoutIcon,
      onClick: () => {
        window.localStorage.removeItem('auth_headers');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('currentUser');
        setCurrentUser({});
        window.location.reload();
      },
    },
  ];

  const { products } = currentUser.company;

  products.forEach(product => {
    if (product.product_type !== 'meetingroom') {
      // イトーキ個社対応で調整アポだけ非表示
      if (isItokiCompanyUser() && product.product_type === 'scheduling') return;
      if (isKhiCompanyUser && product.product_type === 'scheduling') return;
      if (isNissanEmployee() && product.product_type === 'scheduling') return;

      const productInfo = {
        name: productContent[product.product_type].name,
        description: productContent[product.product_type].description,
        product_type: product.product_type,
        isActive: product.active,
        onClick: product.active && productContent[product.product_type].onClick,
        link: !product.active && productContent[product.product_type].link,
      };

      if (product.active) {
        ownedProducts.push(productInfo);
      } else {
        otherProducts.push(productInfo);
      }
    }
  });
  const webViewAccountList = [
    {
      label: <FormattedMessage {...messages.logout} />,
      icon: LogoutIcon,
      onClick: () => {
        webViewUtils.signOut();
      },
    },
  ];

  if (isMobileDevice()) {
    return (
      <MobileHeader {...props}>
        <NavAccount
          currentUser={currentUser}
          accountList={
            webViewUtils.isReactNativeWebView()
              ? webViewAccountList
              : accountList
          }
          showHr={false}
          showCompanyInfo={false}
        />
      </MobileHeader>
    );
  }

  return (
    <HeaderWrapper pb={pb}>
      <HeaderLeft>
        <ProductDropDown
          owned={ownedProducts}
          other={otherProducts}
          otherLabel={
            otherProducts.length > 0 ? (
              <FormattedMessage {...messages.otherName} />
            ) : (
              ''
            )
          }
          ownedLabel={<FormattedMessage {...messages.ownedProducts} />}
          currentApp="meetingroom"
          freeLabel="free"
        />
        <Icon src={RoomsLogoIcon} onClick={() => history.push('/')} />
      </HeaderLeft>
      {!hideRightComponent && (
        <HeaderRight>
          <Anchor href={process.env.REACT_APP_HELPCENTER_URL} target="_blank">
            <FormattedMessage {...messages.helpCenter} />
          </Anchor>
          <LangSelector>
            <LangText
              active={locale === LOCALES.EN}
              onClick={() => setLocale(LOCALES.EN)}
            >
              EN
            </LangText>
            <LangText
              active={locale === LOCALES.JA}
              onClick={() => setLocale(LOCALES.JA)}
            >
              JP
            </LangText>
          </LangSelector>
          <NavAccount
            currentUser={currentUser}
            accountList={accountList}
            showHr={false}
            showCompanyInfo={false}
          />
        </HeaderRight>
      )}
    </HeaderWrapper>
  );
}

Header.defaultProps = {
  hideRightComponent: false,
  styleProps: { pb: '' },
};

Header.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  hideRightComponent: PropTypes.bool,
  styleProps: PropTypes.oneOfType([PropTypes.object]),
};

export default Header;
