import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import humps from 'lodash-humps';

import DropDownButton from 'components/Buttons/DropDownButton';
import messages from 'components/BigCalendar/messages';
import { getCurrentUser, isItokiCompanyUser } from 'utils/userUtils';

export default function CalendarHeaderDropdown({ filterByTime }) {
  const currentUser = getCurrentUser();
  const camelizedUser = humps(currentUser);
  const isSekisuiCompanyUser = camelizedUser?.company?.id === 6885;
  function handleButtonClick(time) {
    filterByTime(time);
  }

  const items = [
    {
      text: <FormattedMessage {...messages.notSpecified} />,
      onClick: () => handleButtonClick(null),
    },
    {
      text: <FormattedMessage {...messages.fifteenMins} />,
      onClick: () => handleButtonClick(15),
    },
    {
      text: <FormattedMessage {...messages.thirtyMins} />,
      onClick: () => handleButtonClick(30),
    },
    {
      text: <FormattedMessage {...messages.sixtyMins} />,
      onClick: () => handleButtonClick(60),
    },
  ];

  return (
    <>
      {!isSekisuiCompanyUser && !isItokiCompanyUser() && (
        <DropDownButton
          buttonText={<FormattedMessage {...messages.filterButtonText} />}
          items={items}
        />
      )}
    </>
  );
}

CalendarHeaderDropdown.propTypes = {
  filterByTime: PropTypes.func.isRequired,
};
