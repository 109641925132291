import React from 'react';
import { Row, Col, Label } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Popover from 'components/Popover';
import IconClock from 'components/Icons/IconClock';
import IconDoorOpen from 'components/Icons/IconDoorOpen';
import IconUserFriends from 'components/Icons/IconUserFriends';
import IconTimes from 'components/Icons/IconTimes';
import IconNotes from 'components/Icons/IconNotes';
import { WordBreakLabel } from 'components/_commons/styled';
import GarbageSvg from 'images/ic_garbage.svg';
import * as colors from 'constants/colors';
import * as eventDetailsButtonTypes from 'constants/eventDetailsButtonTypes';
import {
  getCurrentUser,
  isItokiCompanyUser,
  isRedirectToOnetimeAppointment,
} from 'utils/userUtils';
import {
  EventDetailsContent,
  ModalTitle,
  ColorIndicator,
  Icon,
  ConfirmationDialogButton,
  NotesCol,
} from './styled';
import messages from '../messages';

function EventDetailsPopover({
  open,
  selectedEvent,
  onCancel,
  timeFormat,
  generateEventStatus,
  onDelete,
  buttonType,
  handleMeetingCheckInOut,
}) {
  const formattedStartTime =
    moment(selectedEvent.start).format('MMMM Do ') +
    moment(selectedEvent.start).locale('en').format(timeFormat);
  const formattedEndTime = moment(selectedEvent.end)
    .locale('en')
    .format(timeFormat);
  const currentUser = getCurrentUser();
  const currentUserEmails = [currentUser.email?.toLowerCase()];

  if (currentUser.calendar_email != null || currentUser.calendar_id) {
    currentUserEmails.push(currentUser.calendar_email?.toLowerCase());
    currentUserEmails.push(currentUser.calendar_id?.toLowerCase());
  } else {
    currentUserEmails.push(
      currentUser.company?.meetingroom_provider_email?.toLowerCase(),
    );
  }

  let indicatorColor = '';
  let border;
  switch (
    generateEventStatus(selectedEvent.checkedInAt, selectedEvent.checkedOutAt)
  ) {
    case 'done':
      indicatorColor = colors.doneEventGray;
      break;
    case 'busy':
      indicatorColor = colors.busyEvent;
      break;
    default:
      indicatorColor = colors.white;
      border = `solid 0.5px ${colors.primaryLightGreen}`;
      break;
  }

  const attendeeNames = (selectedEvent?.attendees || [])
    .filter(attendee => !attendee.resource)
    .map(attendee => attendee.name || attendee.email);

  const isPrivate = ['private', 'confidential', 'PRIVATE'].includes(
    selectedEvent.visibility,
  ); // NOTE: Garoonの場合'PRIVATE'

  const generateResourceEventId = () => {
    return `rbc-event ${selectedEvent.resourceId}.${selectedEvent.id}`;
  };

  const reditectsOnetime = isRedirectToOnetimeAppointment();

  if (isPrivate) {
    return (
      <div>
        <Popover
          open={open}
          positionTargetKey="className"
          positionTargetValue={generateResourceEventId()}
          onClickOverlay={onCancel}
          overlay={false}
        >
          <EventDetailsContent>
            <Row padding="16px 16px 14px" width="100%" alignItems="center">
              <Col>
                <ColorIndicator color={indicatorColor} border={border} />
              </Col>
              <Col xs>
                <ModalTitle>
                  <FormattedMessage {...messages.privateEvent} />
                </ModalTitle>
              </Col>
              <Col>
                <IconTimes fill={colors.grayThin} onClick={onCancel} />
              </Col>
            </Row>
            <Row padding="0 56px 16px" width="100%">
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.privateDescription} />
                </Label>
              </Col>
            </Row>
            {buttonType !== eventDetailsButtonTypes.NONE && (
              <Row mb="16px" width="100%" justifyContent="flex-end">
                <Col>
                  <ConfirmationDialogButton
                    className="confirm_button"
                    type="submit"
                    onClick={() => handleMeetingCheckInOut()}
                  >
                    {buttonType === eventDetailsButtonTypes.CHECKIN ? (
                      <FormattedMessage {...messages.checkin} />
                    ) : (
                      <FormattedMessage {...messages.checkout} />
                    )}
                  </ConfirmationDialogButton>
                </Col>
              </Row>
            )}
          </EventDetailsContent>
        </Popover>
      </div>
    );
  }

  return (
    <div>
      <Popover
        open={open}
        positionTargetKey="className"
        positionTargetValue={generateResourceEventId()}
        onClickOverlay={onCancel}
        overlay={false}
      >
        <EventDetailsContent>
          <Row padding="16px 16px 14px" width="100%" alignItems="center">
            <Col>
              <ColorIndicator color={indicatorColor} border={border} />
            </Col>
            <Col xs overflow="hidden">
              <ModalTitle>{selectedEvent.name}</ModalTitle>
            </Col>
            {selectedEvent.deletable &&
              !reditectsOnetime &&
              !isItokiCompanyUser() && (
                <Col>
                  <Icon src={GarbageSvg} onClick={onDelete} />
                </Col>
              )}
            <Col>
              <IconTimes fill={colors.grayThin} onClick={onCancel} />
            </Col>
          </Row>

          <Row padding="0 56px 16px" width="100%">
            <Col xs>
              <Row pb="8px" alignItems="center">
                <Col>
                  <IconClock fill={colors.grayHeading} width={14} height={14} />
                </Col>
                <Col>
                  <Label>{`${formattedStartTime} - ${formattedEndTime}`}</Label>
                </Col>
              </Row>
              <Row pb="8px" alignItems="center">
                <Col>
                  <IconDoorOpen
                    fill={colors.grayHeading}
                    width={14}
                    height={14}
                  />
                </Col>
                <Col xs>
                  <WordBreakLabel>{selectedEvent.resourceTitle}</WordBreakLabel>
                </Col>
              </Row>
              {attendeeNames.length > 0 && (
                <Row alignItems="flex-start">
                  <Col>
                    <IconUserFriends
                      fill={colors.grayHeading}
                      width={14}
                      height={14}
                    />
                  </Col>
                  <Col
                    style={{ paddingTop: '6px' }}
                    display="flex"
                    flexDirection="column"
                    xs
                  >
                    {attendeeNames.map(attendeeName => (
                      <WordBreakLabel key={attendeeName}>
                        {attendeeName}
                      </WordBreakLabel>
                    ))}
                  </Col>
                </Row>
              )}
              {selectedEvent.notes != null && (
                <Row pb="8px" alignItems="flex-start">
                  <Col>
                    <IconNotes
                      fill={colors.grayHeading}
                      width={14}
                      height={14}
                    />
                  </Col>
                  <NotesCol xs>
                    <Label style={{ whiteSpace: 'pre-line' }}>
                      {selectedEvent.notes}
                    </Label>
                  </NotesCol>
                </Row>
              )}
            </Col>
          </Row>
          {buttonType !== eventDetailsButtonTypes.NONE && (
            <Row mb="16px" width="100%" justifyContent="flex-end">
              <Col>
                <ConfirmationDialogButton
                  className="confirm_button"
                  type="submit"
                  onClick={() => handleMeetingCheckInOut()}
                >
                  {buttonType === eventDetailsButtonTypes.CHECKIN ? (
                    <FormattedMessage {...messages.checkin} />
                  ) : (
                    <FormattedMessage {...messages.checkout} />
                  )}
                </ConfirmationDialogButton>
              </Col>
            </Row>
          )}
        </EventDetailsContent>
      </Popover>
    </div>
  );
}

EventDetailsPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedEvent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeFormat: PropTypes.string.isRequired,
  generateEventStatus: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  buttonType: PropTypes.string.isRequired,
  handleMeetingCheckInOut: PropTypes.func.isRequired,
};

export default EventDetailsPopover;
